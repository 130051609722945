import { useQuery } from 'react-query';
import get from 'lodash/get';
import qs from 'qs';
import GET_PRODUCT from 'containers/ProductPage/gql/queryGetProduct';
import GET_RELATED_PRODUCTS from 'containers/ProductPage/gql/queryGetMultipleProducts';
import requestGql, { querySetting } from 'utils/requestGql';
import useGetSourceCode from 'utils/Hooks/useGetSourceCode';
import request from 'utils/request';

export const PRODUCT_QUERY_KEY = {
  SINGLE_PRODUCT: 'productPage.singleProduct',
  MULTIPLE_PRODUCTS: 'productPage.multipleProducts',
  SPECIAL_PRODUCTS: 'productPage.getSpecialProducts',
  PRODUCT_BLOG: 'productPage.productBlog',
  GET_PRODUCT_BY_SKU: 'productPage.getProductBySku',
};

export const getSpecialProducts = payload =>
  request('GET', `/products${qs.stringify(payload, { addQueryPrefix: true })}`);
export const getProductBlog = productId =>
  request('GET', `/blog/article_category/product/${productId}`);
export const getProductBySku = sku =>
  request('GET', `/special-vendor/products/${sku}`).then(product => {
    const eligibility = product.custom_attributes.find(
      attr => attr.attribute_code === 'eligibility',
    );

    return {
      ...product,
      eligibility: Number(eligibility?.value ?? 0),
    };
  });

const getProductQuery = (id, sourceCode) => requestGql(GET_PRODUCT(id, sourceCode));
const getMultipleProductsQuery = id => requestGql(GET_RELATED_PRODUCTS(id));

export const useGetProductBySku = sku =>
  useQuery(
    sku && [PRODUCT_QUERY_KEY.GET_PRODUCT_BY_SKU, sku],
    () => getProductBySku(sku),
    querySetting,
  );

export const useGetProduct = id => {
  const sourceCode = useGetSourceCode();
  return useQuery(
    [PRODUCT_QUERY_KEY.SINGLE_PRODUCT, { id, sourceCode }],
    () => getProductQuery(id, sourceCode).then(response => get(response, 'products.items[0]', [])),
    { ...querySetting, refetchOnMount: 'always', enabled: sourceCode !== undefined },
  );
};

export const useGetMultipleProducts = (ids, id) =>
  useQuery(
    [PRODUCT_QUERY_KEY.MULTIPLE_PRODUCTS, id],
    () => getMultipleProductsQuery(ids).then(response => get(response, 'products.items', [])),
    querySetting,
  );

export const useGetSpecialProducts = specialVendors => {
  const sourceCode = useGetSourceCode();

  const data = {
    'searchCriteria[filterGroups][1][filters][0][conditionType]': 'in',
    'searchCriteria[filterGroups][1][filters][0][field]': 'entity_id',
    'searchCriteria[filterGroups][1][filters][0][value]': specialVendors,
    'searchCriteria[filterGroups][2][filters][0][conditionType]': 'eq',
    'searchCriteria[filterGroups][2][filters][0][field]': 'source_code',
    'searchCriteria[filterGroups][2][filters][0][value]': sourceCode,
    'searchCriteria[filterGroups][3][filters][0][conditionType]': 'eq',
    'searchCriteria[filterGroups][3][filters][0][field]': 'status',
    'searchCriteria[filterGroups][3][filters][0][value]': 1,
  };

  return useQuery(
    [PRODUCT_QUERY_KEY.SPECIAL_PRODUCTS, sourceCode, specialVendors],
    () => getSpecialProducts(data).then(({ items }) => items),
    {
      ...querySetting,
      enabled: !!(sourceCode && specialVendors),
    },
  );
};

export const useGetProductBlog = id =>
  useQuery([PRODUCT_QUERY_KEY.PRODUCT_BLOG, id], () => getProductBlog(id), querySetting);
