import store from 'store';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
// import { sentrySetUser } from 'utils/Sentry';
import { deleteAuthorization, setAuthorization } from 'utils/Auth';
import { useGetCheckoutQuote } from 'containers/CheckoutPage/queries';
import routes from 'utils/routes';
import { useGetCartQuote } from 'containers/CartProvider/queries';
// import { getProfile } from 'containers/UserProfile/api';
// import {
//   deleteBuyNow,
//   deleteProceedToCheckout,
//   getBuyNow,
//   getProceedToCheckout,
// } from 'utils/Checkout';
// import useCheckout from 'utils/Hooks/useCheckout';
// import { resetCart } from 'containers/CartProvider/slices';
// import useBuyNow from 'utils/Hooks/useBuyNow';
import { setAuth } from 'containers/AuthorizationProvider/slices';
import { CATALOG_QUERY_KEY } from 'containers/CatalogPage/queries';

const usePostLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutateAsync: getCheckoutQuote } = useGetCheckoutQuote();
  const { refetch: refetchCartQuote } = useGetCartQuote();
  // const { checkout } = useCheckout();
  // const { buyNow } = useBuyNow();

  return async (token, route, toDashboard = true) => {
    // const auth = getAuthorization();
    queryClient.removeQueries([CATALOG_QUERY_KEY.GET_PRODUCTS]);
    deleteAuthorization();
    setAuthorization(token);
    dispatch(setAuth({ type: 'user', token }));

    // const customer = await getProfile();
    // const { id: customerId } = customer;
    // if (auth.token && customerId) {
    //   sentrySetUser(customer);
    //
    //   await assignCustomerToGuestCart(
    //     {
    //       customerId,
    //       storeId: 1,
    //     },
    //     auth.token,
    //   );
    // }

    // Check if a Buy Now or Proceed to Checkout was initiated before log in
    // const buyNowItems = getBuyNow();
    // const proceedToCheckout = getProceedToCheckout();
    //
    // if (buyNowItems || proceedToCheckout) {
    //   if (buyNowItems) {
    //     buyNow(buyNowItems);
    //   } else if (proceedToCheckout) {
    //     await dispatch(resetCart());
    //     await checkout(proceedToCheckout);
    //   }
    //
    //   deleteBuyNow();
    //   deleteProceedToCheckout();
    //   return;
    // }

    await getCheckoutQuote();
    await refetchCartQuote();
    if (route) {
      const redirectUrl =
        routes?.[route] || store.get('last_url') || (toDashboard ? routes.dashboard : routes.home);
      if (redirectUrl) {
        store.remove('last_url');
        history.push(redirectUrl);
      }
    }
  };
};

export default usePostLogin;
