import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import he from 'he';
import { useMemo } from 'react';
import { useGetCms } from 'containers/CustomerService/queries';
import useGetVendorCode from 'utils/Hooks/useGetVendorCode';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(1.5, 2.5),
    // backgroundColor: theme.palette.background.peach,
    // '& > p': {
    //   margin: 0,
    // },
    // [theme.breakpoints.up('md')]: {
    //   top: 104,
    //   justifyContent: 'center',
    //   position: 'relative',
    //   zIndex: 2,
    // },
  },
}));

const GlobalNotice = () => {
  const classes = useStyles();
  const vendorCode = useGetVendorCode();
  const { data: cms } = useGetCms(vendorCode ? `${vendorCode}-notification` : '');
  const content = useMemo(() => {
    if (cms) {
      return he.decode(cms?.content);
    }
    return null;
  }, [cms]);
  return cms?.content ? (
    <Box className={classes.root}>
      <Box dangerouslySetInnerHTML={{ __html: content }} />
    </Box>
  ) : null;
};

export default GlobalNotice;
